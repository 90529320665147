.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #333;
}

.blog-post-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.blog-post-list {
  counter-reset: item;
  list-style: none;
  margin: 0;
  padding: 0;
}

.blog-post-item {
  counter-increment: item;
  margin-bottom: 20px;
  line-height: 1.5;
  position: relative;
  padding-left: 30px;
}

.blog-post-item::before {
  content: counter(item);
  font-weight: bold;
  color: #0072C6;
  background-color: #fff;
  border: 2px solid #0072C6;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 10px;
}

.blog-post p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #666;
}
