html,
body {
  font-size: 18px;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  Background-image: linear-gradient(#00A4CCFF, rgb(168, 238, 247));  
  color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* main {
  margin: 2rem auto;
  max-width: 300px;
} */


/* input {
  display: block;
  width: 100%;
  padding: 0.25rem;
  margin-bottom: 1rem;
} */

button {
  padding: 0.25rem 0.5rem;
}

.board {
  margin-bottom: 1rem;
}

.row {
  display: flex;
}

.square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 1px solid white;
  font-size: 2rem;
}


/* my css */

.title,
.study-text {
  text-align: center;


}



.study-text {
  margin-top: 2%;
}

.user-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

input {
  vertical-align: middle;
  margin: 1em;
  padding: 0.5em;
  background-color: white;
  border: 1px solid #ddd;
}

.user-form button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
}

#twoHourSlotBtn {

  padding: 10px 20px;
  background-color: rgb(31, 71, 135);
  border: 1px solid #ddd;
  color: white;
}
#fourHourSlotBtn {

  padding: 10px 20px;
  background-color: rgb(5, 28, 65);
  border: 1px solid #ddd;
  color: white;
}
#oneHourSlotBtn {

  padding: 10px 20px;
  background-color: rgb(37, 90, 176);
  border: 1px solid #ddd;
  color: white;
}


#oneHourSlotBtn:hover {

  background-color: royalblue;
}
#twoHourSlotBtn:hover {

  background-color: royalblue;
}
#fourHourSlotBtn:hover {

  background-color: royalblue;
}


.user-form button:hover {
  background-color: royalblue;
}

.scroll-div {
  margin: auto;
  width: 50%;
  
}

li {
  list-style-type: none;
}

.available-text,
.timeSelected {
  text-align: center;
}

.scroll-list {
  height: 400px;
  font-weight: bold;
  overflow: hidden;
  overflow-y: scroll;
  background-color: white;
  color: #2e0bcc;
  padding-top: 20px;
  padding-bottom: 30px;
  /* margin-left: 20px; */
}

.centered {
  text-align: center;
}

.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #00A4CCFF;
  color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: #aa0423;
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}